import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';

import { AuthService } from './auth/auth.service';

import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { NoAccessGuard } from './guards/no-access.guard';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpModule,
    Ng2Webstorage.forRoot({
      prefix: 'mass-storage',
      separator: '.',
      caseSensitive: true
  }),
  ],
  declarations: [],
  providers: [
    AuthGuard,
    NoAuthGuard,
    NoAccessGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
