import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';

import { FuseConfirmDialogDeleteComponent } from '@fuse/components/confirm-dialog-delete/confirm-dialog-delete.component';

@NgModule({
    declarations: [
        FuseConfirmDialogDeleteComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        FuseConfirmDialogDeleteComponent
    ],
})
export class FuseConfirmDialogDeleteModule
{
}
