import { Injectable } from '@angular/core';
@Injectable()
export class LoaderService {

    public showLoading (): void {
        this.loadingPage.classList.add('show');
    }
    public hideLoading(): void {
        setTimeout(() => {
            this.loadingPage.classList.remove('show');
        }, 600);
    }

    public show (): void {
        this.loadingPage.classList.add('active');
    }
    public hide(): void {
        setTimeout(() => {
            this.loadingPage.classList.remove('active');
        }, 600);
    }


    get loadingPage(): HTMLElement{
        return <HTMLElement>document.querySelector('.full-loading-page');
    }
}
