import { DatePipe } from '@angular/common';
import { AbstractControl } from '@angular/forms';

export class FuseUtils {
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns {any}
     */
    public static filterArrayByString(mainArr, searchText): any {
        if (searchText === '') {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    public static searchInObj(itemObj, searchText): boolean {
        for (const prop in itemObj) {
            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }

            const value = itemObj[prop];

            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            else if (Array.isArray(value)) {
                if (this.searchInArray(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    public static searchInArray(arr, searchText): boolean {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void {
        if (array.indexOf(item) === -1) {
            array.push(item);
        }
        else {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }


    public static showErrors(errors: any[]): string {
        // tslint:disable-next-line:forin
        for (const i in errors) {
            return errors[i][0];
        }
    }
    public static stringStatus(status: any, flag_pending = 0): string {

        let str = '';

        switch (status) {
            case 'SIGNATURE-CONTRACT':
                str = 'FIRMA CONTRATO';
                break;
            case 'COMPLETED':
                str = 'COMPLETADO';
                break;
            case 'ATTENDED':
                str = 'ATENDIDO';
                break;
            case 'CONFIRMED':
                str = 'CONFIRMADO';
                break;
            case 'GIVE-LOW':
                str = 'ARCHIVADO';
                break;
            case 'NOT-QUALIFY':
                str = 'NO CALIFICA';
                break;
            case 'VISIT-LOCAL':
                str = 'VISITA LOCAL';
                break;
            case 'CANNIBALIZATION':
                str = 'CANIBALIZACIÓN';
                break;
            case 'OTHER-CITIES':
                str = 'OTRAS CIUDADES';
                break;
            default:
                str = flag_pending === 1 ? 'PENDIENTE APROB' : 'PENDIENTE';
                break;
        }

        return str;
    }
    public static formatDate(date: any | null): any {
        return  new DatePipe('en-US').transform(date, 'yyyy-MM-d');
    }

    public static setVersion(version: string): void {
        console.log(
            `%cADMIN MASS%cv ${version}`, `padding: 5px; border-radius: 4px 0 0 4px; 
            background: linear-gradient(to bottom, #203d77 0%,#18225b 100%); color: #FFF; 
            text-transform: uppercase; font-size: 10px;font-family: sans-serif; 
            font-weight: bold;text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3)`,
            `padding: 5px; border-radius: 0 4px 4px 0; background: linear-gradient(to bottom, #f5a623 0%,#ed9200 100%); 
            color: #FFF; text-transform: uppercase; font-size: 10px;font-family: sans-serif`
        );
    }
 
}

