import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    Renderer2,
    ElementRef
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from './../../../core/auth/auth.service';

import { navigation } from 'app/navigation/navigation';
import { LocalStorageService } from 'ngx-webstorage';
import {
    AnimationPlayer,
    AnimationBuilder,
    animate,
    style
} from '@angular/animations';
import { NotificationService } from './notification.services';
import { Notifications } from './notification';
import { ToolbarServices } from './toolbar.services';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    showMenu: boolean;
    showNotification: boolean;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    notifications: Notifications = <Notifications>{
        count: 0,
        data: []
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    userName: any;

    private _backdrop: HTMLElement | null = null;
    private _player: AnimationPlayer;
    // Invisible overlay
    @Input()
    invisibleOverlay: boolean;

    /* Var Menu */
    // Open
    @HostBinding('class.open')
    opened: boolean;

    // isLockedOpen
    @HostBinding('class.locked-open')
    isLockedOpenMenu: boolean;

    // Opened changed
    @Output()
    openedChangedMenu: EventEmitter<boolean>;

    /* Var Menu */
    // Open
    @HostBinding('class.open')
    openedNotification: boolean;


    // Opened changed
    @Output()
    openedChangedNotification: EventEmitter<boolean>;

    @HostBinding('class.animations-enabled')
    private _animationsEnabled: boolean;
    rolName: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private router: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _authService: AuthService,
        private _storage: LocalStorageService,
        private _animationBuilder: AnimationBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _notificationService: NotificationService,
        private _toolbarServices: ToolbarServices
    ) {
        this.invisibleOverlay = false;
        this.opened = false;

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        this.openedChangedMenu = new EventEmitter();

        this.openedChangedNotification = new EventEmitter();

        this.rolName = this._authService.getRol();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });

        if (this._storage.retrieve('user')) {
            const user = this._storage.retrieve('user');
            this.userName = user.name;
        }
        this.listNotification();

        this._toolbarServices.notificationOnChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(status => {
                if (!Boolean(status)) {
                    this._close();
                }
            });
    }

    listNotification(): void {
        // Listen for router events
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((event: NavigationEnd) => {
                if (event.url !== '/') {
                    this._notificationService.get().then(res => {
                        this.notifications = res;
                    });
                }
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        this._authService.logout().subscribe(
            () => {
                this.router.navigate(['/']);
            },
            () => {
                this.router.navigate(['/']);
            }
        );
    }
    openMenu(): void {
        this.showNotification = false;
        if (!this.showMenu) {
            this._open();
            this.showMenu = true;
        }else {
            this.showMenu = false;
          
        }
    }

    openNotification(): void {
        this.showMenu = false;
        if (!this.showNotification) {
            this._open();
            this.showNotification = true;
        }else {
            this.showNotification = false;
          
        }
        
        if (this.notifications.count > 0) {
            this._notificationService.update().then(res => {
                this.notifications.count = 0;
            });
        }
    }

    /**
     * Open Menu
     */
    _open(): void {
        if (this.opened || this.isLockedOpenMenu) {
            return;
        }

        // Enable the animations
        this._enableAnimations();

        // Show the backdrop
        this._showBackdrop();

        // Set the opened status
        this.opened = true;

        // Emit the 'openedChanged' event
        this.openedChangedMenu.emit(this.opened);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

   
    /**
     * Show the backdrop
     *
     * @private
     */
    private _showBackdrop(): void {
        // Create the backdrop element
        this._backdrop = this._renderer.createElement('div');

        // Add a class to the backdrop element
        this._backdrop.classList.add('fuse-sidebar-overlay');

        // Add a class depending on the invisibleOverlay option
        if (this.invisibleOverlay) {
            this._backdrop.classList.add(
                'fuse-sidebar-overlay-invisible-z-index'
            );
        }

        // Append the backdrop to the parent of the sidebar
        this._renderer.appendChild(
            this._elementRef.nativeElement.parentElement,
            this._backdrop
        );

        // Create the enter animation and attach it to the player
        this._player = this._animationBuilder
            .build([animate('300ms ease', style({ opacity: 1 }))])
            .create(this._backdrop);

        // Play the animation
        this._player.play();

        // Add an event listener to the overlay
        this._backdrop.addEventListener('click', () => {
            this._close();

            if (this.showMenu) {
                this.showMenu = false;
            }

            if (this.showNotification) {
                this.showNotification = false;
            }

        });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Hide the backdrop
     *
     * @private
     */
    private _hideBackdrop(): void {
        if (!this._backdrop) {
            return;
        }

        // Create the leave animation and attach it to the player
        this._player = this._animationBuilder
            .build([animate('300ms ease', style({ opacity: 0 }))])
            .create(this._backdrop);

        // Play the animation
        this._player.play();

        // Once the animation is done...
        this._player.onDone(() => {
            // If the backdrop still exists...
            if (this._backdrop) {
                // Remove the backdrop
                this._backdrop.parentNode.removeChild(this._backdrop);
                this._backdrop = null;
            }
        });
        
        this.invisibleOverlay = false;
        
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    _close(): void {
        if (!this.opened || this.isLockedOpenMenu) {
            return;
        }

        // Enable the animations
        this._enableAnimations();

        // Hide the backdrop
        this._hideBackdrop();

        // Set the opened status
        this.opened = false;

        // Emit the 'openedChanged' event
        this.openedChangedMenu.emit(this.opened);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }
 
    /**
     * Enable the animations
     *
     * @private
     */
    private _enableAnimations(): void {
        // Return if animations already enabled
        if (this._animationsEnabled) {
            return;
        }

        // Enable the animations
        this._animationsEnabled = true;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    selectMenu(value): void {
        this.router.navigate([`${value}`]).then(() => {
            this._close();
        });
    }
    goProfile(): void {
        this.router.navigate(['/settings/users/profile']);
    }
}
