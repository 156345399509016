import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import { AuthService } from './../auth/auth.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { LoaderService } from '@fuse/services/loader.services';
import { tap, catchError } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(
        public auth: AuthService,
        private router: Router,
        private loader: LoaderService,
        private authService: AuthService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.loader.showLoading();
        if (this.auth.isLogged()) {
            request = request.clone({
                headers: request.headers.set(
                    'Authorization',
                    `Bearer ${this.auth.getToken()}`
                )
            });
        }

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.loader.hideLoading();
                    }
                },
                (error: HttpErrorResponse) => {
                    const errorCode = error.status;
                    if (errorCode === 401 || errorCode === 500) {
                        this.authService.failedRequestCode();
                    }
                }
            ),
            catchError(error => {
                this.loader.hideLoading();
                return Observable.throw(error);
            }) as any
        );
    }
}
