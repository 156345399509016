import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { ToolbarNotificationComponent } from './toolbar-notification/toolbar-notification.component';
import { NotificationService } from './notification.services';


@NgModule({
    declarations: [
        ToolbarComponent,
        ToolbarMenuComponent,
        ToolbarNotificationComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule
    ],
    exports     : [
        ToolbarComponent,
        ToolbarMenuComponent,
        ToolbarNotificationComponent
    ],
    providers: [
        NotificationService
    ]
})
export class ToolbarModule
{
}
