
export function getUrlApi(): string {
    const hostName = document.location.hostname;
    let apiURL = '';
    /**
     * PRD
     */
    if ( (hostName === 'mass-admin.tiendasmass.com.pe') || (hostName === 'www.mass-admin.tiendasmass.com.pe') ) {
        apiURL = 'https://mass-api.tiendasmass.com.pe/api/v1';
    }
    /**
     * QA
     */
    else {
        apiURL = 'https://mass-api.iz.pe/api/v1';
    }

    // apiURL = 'https://mass-api.patternqa.com/api/v1';
//  apiURL = 'http://mass-api.test/api/v1';

    return apiURL;
}
export function getUrlExcel(): string {
    const hostName = document.location.hostname;
    let apiURL = '';
    
    /**
     * PRD
     */
    if ( (hostName === 'mass-admin.tiendasmass.com.pe') || (hostName === 'www.mass-admin.tiendasmass.com.pe') ) {
        apiURL = 'https://mass-api.tiendasmass.com.pe/excel/';
    }
    /**
     * QA
     */
    else{
        apiURL = 'https://mass-api.iz.pe/excel/';
    }

    // apiURL = 'https:/mass-api.patternqa.com/excel/';
// apiURL = 'http://mass-api.test/api/v1';

    return apiURL;
}

export const VARIABLES = {
    ROLES: {
        COLABORADOR: 'Colaborador',
        ADMIN: 'Admin',
        PROPIETARIO: 'Propietario',
        SUPERVISOR: 'Supervisor',
        LOCAL: 'Legal'
    } 
}

export const MIN_LOCAL_AREA = 180;