import { NgModule, enableProdMode } from '@angular/core';
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
    MatButtonModule,
    MatIconModule,
    GestureConfig
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ApiService } from './core/services/api/api.service';
import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from '@fuse/services/loader.services';
import { AgmCoreModule } from '@agm/core';

enableProdMode();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            extendedTimeOut: 1000
        }),
        
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDVQxWZKo4_Fhf77XPxiuk_JBS2JthC5jE',
            libraries: ['places']
        })
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        ApiService,
        LoaderService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
