import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { VARIABLES } from 'environments/config';

@Injectable()
export class NoAccessGuard implements CanActivate {
    constructor(private _authService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const rolName =  this._authService.getRol();
        if (rolName === VARIABLES.ROLES.ADMIN) {
            return true;
        }
        return false;
    }
}
