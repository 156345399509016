import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        type: 'group',
        children: [
            {
                id: 'reports',
                title: 'Reportes',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard/reports',
                exactMatch: true,
                colab: true
            },

            {
                id: 'oportunities',
                title: 'Oportunidades',
                type: 'item',
                icon: 'store_mall_directory',
                url: '/dashboard/opportunities',
                colab: true
            },
            {
                id: 'hot_areas',
                title: 'Mapa',
                type: 'item',
                icon: 'hot_tub',
                url: '/dashboard/zonas-calientes',
                colab: true
            },
           
           
            {
                id: 'config',
                title: 'Configuración',
                type: 'item',
                icon: 'settings',
                url: '/settings',
                colab: false
            }
        ]
    }
];

export const navigationUser: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        type: 'group',
        children: [
            {
                id: 'oportunities',
                title: 'Oportunidades',
                type: 'item',
                icon: 'store',
                url: '/dashboard/opportunities'
            },
            {
                id: 'hot_areas',
                title: 'Mapa',
                type: 'item',
                icon: 'hot_tub',
                url: '/dashboard/zonas-calientes'
            }
        ]
    }
];
