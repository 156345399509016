import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { PermissionService } from '@fuse/services/permission.service';
import { VARIABLES } from 'environments/config';
@Injectable()
export class AuthService {
    @LocalStorage() public user: any;
    @LocalStorage() public access_token: string;

    constructor(private http: Http,
        private _apiService: ApiService,
        private storage: LocalStorageService,
        private _router: Router,
        private _permissionService: PermissionService) { }

    login(auth): Observable<any> {
        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const data = `email=${auth.user}&password=${auth.password}`;

        return this.http.post(this._apiService.login.auth(), data, httpOptions).pipe(
            map((res: any) => {
                const _data = res.json();
                this.user = _data.user;
                this.access_token = _data.access_token;
                this.storage.store('rol', this.user.rol.name);
                this.storage.store('access_token', this.access_token);
            })
        );
    }

    logout(): any {

        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.getToken()}`
            })
        };
        return this.http
            .get(this._apiService.login.logout(), httpOptions)
            .pipe(map(res => {
                this.storage.clear('user');
                this.storage.clear('access_token');
                this.storage.clear('rol');
                res.json();
            }));
    }

    isLogged(): boolean {
        return !!this.storage.retrieve('user');
    }
    getRol(): string {
        return this.storage.retrieve('rol');
    }

    getToken(): string {
        return this.storage.retrieve('access_token') || '';
    }
    failedRequestCode(): void {
        this.storage.clear('user');
        this.storage.clear('access_token');
        this.storage.clear('rol');
        this._router.navigate(['/']);
    }
}
