import { Component, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'toolbar-menu',
    templateUrl: './toolbar-menu.component.html',
    styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent {
    @Output() menuSelect: EventEmitter<string> = new EventEmitter<string>();
    rolName: any;

    constructor(private _storage: LocalStorageService, private _authService: AuthService) {
        this.rolName = this._authService.getRol();
    }

    selectMenu(value): void {
        this.menuSelect.emit(value);
    }
}
