import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as jwt_decode from 'jwt-decode';

export const statusCodes = {
    offline: 0,
    code: 200,
    no_content: 204,
    multiple_choices: 300,
    internal_server_error: 500,
    not_found: 404,
    not_authorized: 401,
    status: 'ok'
};

export interface ApiLocal {
    lead: () => string;
    lead_map: () => string;
    lead_pending: () => string;
    lead_completed: () => string;
    addAttach: () => string;
    update: (id) => string;
    finish: (id) => string;
    delete_attach: (lead_id, id) => string;
    read: (id) => string;
    owner_lead: () => string;
    ubigeoComplete: () => string;
    ubigeo: () => string;
    ubigeo_departments: () => string;
    ubigeo_provinces: (department_id) => string;
    ubigeo_districts: (department_id, province_id) => string;
    ubigeo_update_status: () => string;
    bank: () => string;
    status: (id) => string;
    addAttachId: (id) => string;
    deleteAttachId: (lead_id, id) => string;
    updateAuthorization: (id) => string;
    reniec: () => string;
    commits: (id) => string;
    lead_messages: (id) => string;
    delete_owner: (id) => string;
    plantsType: () => string;
}

export interface Zone {
    list: () => string;
    search:(page) => string;
    stats: () => string;
    show: (id) => string;
    create: () => string;
    update: (id) => string;
    delete: (id) => string;
    districts: (id) => string;
    storeDistricts: (id) => string;
}

export interface Filters {
    list: () => string;
}

export interface HotZone {
    list: () => string;
    create: () => string;
    update: (id) => string;
}
export interface Scoring {
    ubigeo: () => string;
    price: (id) => string;
    score: () => string;
    delete_factor: (id) => string;
}

export interface RoleUser {
    roles: () => string;
    roleSearch: (id) => string;
    listUsers: () => string;
    show: (id) => string;
    create: () => string;
    update: (id) => string;
    transfer: () => string;
    delete: () => string;
}

export interface ApiUser {
    create: () => string;
    login: () => string;
    me: () => string;
    update: () => string;
    reset_password: () => string;
    perfil: () => string;
}

export interface ApiReport {
    stats: () => string;
    report: () => string;
    leads: () => string;
}

export interface Grid {
    create: () => string;
    update: (id) => string;
    delete: (id) => string;
    list: () => string;
}

export interface Price {
    list: () => string;
    create: () => string;
}

export interface Opportunity {
    ubigeo: () => string;
    list: () => string;
    lead: () => string;
    cancel: (code) => string;
    note: () => string;
    export: () => string;
    email: (code) => string;
}

export interface Logs {
    list: () => string;
    show: (id) => string;
}
export interface Fq {
    update: (id) => string;
}

export interface Notification {
    list: () => string;
    update: () => string;
}

export interface Login {
    auth: () => string;
    logout: () => string;
}

export interface Plant {
    list: () => string;
    add: () => string;
    upload: () => string;
}
 
export interface Product {
    list: (page) => string;
    create: () => string;
    update: (id) => string;
    delete: (id) => string;
}

export interface Store {
    list: (page) => string;
    create: () => string;
    update: (id) => string;
    delete: (id) => string;
    ubigeo: () => string;
}

export interface SearchZone {
    list: (page) => string;
    show: (id) => string;
    create: () => string;
    createImport: () => string;
    update: (id) => string;
    delete: (id) => string;
}

@Injectable()
export class ApiService {
    url: string;
    constructor() {
        this.url = environment.api;
    }
    public login: Login = {
        auth: () => `${this.url}/auth/login`,
        logout: () => `${this.url}/auth/logout`
    };

    public plant: Plant = {
        list: () => `${this.url}/setting/plant-type`,
        add: () => `${this.url}/setting/plant-type/1`,
        upload: () => `${this.url}/setting/upload`
    };

    public logs: Logs = {
        list: () => `${this.url}/setting/log`,
        show: id => `${this.url}/setting/log/${id}`
    };

    public fq: Fq = {
        update: id => `${this.url}/opportunity/lead/${id}/qualitative-factor`
    };

    public notification: Notification = {
        list: () => `${this.url}/opportunity/notification`,
        update: () => `${this.url}/opportunity/notification`
    };

    public opportunity: Opportunity = {
        ubigeo: () => `${this.url}/ubigeo/district`,
        list: () => `${this.url}/opportunity/lead?pagination=true`,
        lead: () => `${this.url}/opportunity/lead/`,
        cancel: code => `${this.url}/opportunity/${code}/cancelled`,
        note: () => `${this.url}/opportunity`,
        export: () => `${this.url}/opportunity/export/lead`,
        email: code => `${this.url}/opportunity/${code}/send-email`
    };

    public local: ApiLocal = {
        lead: () => `${this.url}/lead`,
        lead_map: () => `${this.url}/opportunity/lead`,
        lead_pending: () => `${this.url}/opportunity/lead?status=PENDING`,
        lead_completed: () => `${this.url}/opportunity/lead?status=COMPLETED`,
        addAttach: () => `${this.url}/attach/simple`,
        update: id => `${this.url}/lead/${id}`,
        finish: id => `${this.url}/lead/${id}/finish`,
        delete_attach: (lead_id, id) =>
            `${this.url}/lead/${lead_id}/attach/${id}`,
        read: id => `${this.url}/lead/${id}`,
        owner_lead: () => `${this.url}/opportunity/lead`,
        ubigeo: () => `${this.url}/ubigeo/district?deparment=Lima`,
        ubigeoComplete: () => `${this.url}/ubigeo/complete`,
        ubigeo_departments: () => `${this.url}/ubigeo?department_id`,
        ubigeo_provinces: (department_id) => `${this.url}/ubigeo?provinces=1&department_id=${department_id}`,
        ubigeo_districts: (department_id, province_id) => `${this.url}/ubigeo?districts=1&department_id=${department_id}&province_id=${province_id}`,
        ubigeo_update_status:() => `${this.url}/ubigeo/update/status`,
        bank: () => `${this.url}/bank`,
        status: id => `${this.url}/opportunity/lead/${id}/change-status`,
        addAttachId: id => `${this.url}/lead/${id}/attach`,
        deleteAttachId: (lead_id, id) =>
            `${this.url}/lead/${lead_id}/attach/${id}`,
        updateAuthorization: id =>
            `${this.url}/opportunity/lead/${id}/authorization`,
        reniec: () => `${this.url}/document`,
        commits: id => `${this.url}/opportunity/${id} /commits`,
        delete_owner: id => `${this.url}/opportunity/owner_lead/${id}`,
        lead_messages: id => `${this.url}/opportunity/${id}/lead_messages`,


        plantsType: () => `${this.url}/lead/plant-type`
    };
    public user: ApiUser = {
        create: () => `${this.url}/user`,
        login: () => `${this.url}/auth/login`,
        me: () => `${this.url}/user/me`,
        update: () => `${this.url}/user`,
        reset_password: () => `${this.url}/auth/password/reset`,
        perfil: () => `${this.url}/user`
    };

    public zone: Zone = {
        list: () => `${this.url}/zone`,
        search: page => `${this.url}/zone/list?pagination=true&page=${page}&pages=10`,
        stats: () => `${this.url}/zone/stats`,
        show: id => `${this.url}/zone/${id}`,
        create: () => `${this.url}/zone`,
        update: id => `${this.url}/zone/${id}`,
        delete: id => `${this.url}/zone/${id}`,
        districts: id => `${this.url}/zone/${id}/districts`,
        storeDistricts: (id) => `${this.url}/zone/${id}/district`,
    };

    public filter: Filters = {
        list: () => `${this.url}/filter`
    };

    public scoring: Scoring = {
        ubigeo: () => `${this.url}/ubigeo/price`,
        price: id => `${this.url}/ubigeo/price/${id}`,
        score: () => `${this.url}/setting/score`,
        delete_factor: id =>
            `${this.url}/setting/score/qualitative-factor/${id}`
    };

    public hot_zone: HotZone = {
        list: () => `${this.url}/hotzone`,
        create: () => `${this.url}/hotzone`,
        update: id => `${this.url}/hotzone/${id}`
    };

    public roleUser: RoleUser = {
        roles: () => `${this.url}/role`,
        roleSearch: id => `${this.url}/role/${id}/search`,
        listUsers: () => `${this.url}/user/worker`,
        show: id => `${this.url}/user/worker/${id}`,
        create: () => `${this.url}/user/worker`,
        update: id => `${this.url}/user/worker/${id}`,
        transfer: () => `${this.url}/user/worker/transfer-account`,
        delete: () => `${this.url}/user/worker/delete`,
    };
    public report: ApiReport = {
        stats: () => `${this.url}/report/stats`,
        report: () => `${this.url}/opportunity/report`,
        leads: () => `${this.url}/opportunity/report/lead?pagination=true`,
    };

    public grid: Grid = {
        create: () => `${this.url}/grilla/head`,
        update: id => `${this.url}/grilla/head`,
        delete: id => `${this.url}/grilla/head/${id}`,
        list: () => `${this.url}/grilla/head`
    };

    public price: Price = {
        create: () => `${this.url}/setting/price-negotiate`,
        list: () => `${this.url}/setting/price-negotiate`
    };
 
    public product: Product = {
        list: page => `${this.url}/product?pagination=true&page=${page}&pages=10`,
        create: () => `${this.url}/product`,
        update: id => `${this.url}/product/${id}`,
        delete: id => `${this.url}/product/${id}`
    };

    public store: Store = {
        list: page => `${this.url}/local?pagination=true&page=${page}&pages=10`,
        create: () => `${this.url}/local`,
        update: id => `${this.url}/local/${id}`,
        delete: id => `${this.url}/local/${id}`,
        ubigeo: () => `${this.url}/ubigeo`,
    };

    public getTokenCoordenate(token: string): string {
        return '';
    }

    public searchZone: SearchZone = {
        list: page => `${this.url}/searchzone?pagination=true&page=${page}&pages=10`,
        show: id => `${this.url}/searchzone/${id}`,
        create: () => `${this.url}/searchzone`,
        createImport: () => `${this.url}/searchzone/import`,
        update: id => `${this.url}/searchzone/${id}`,
        delete: id => `${this.url}/searchzone/${id}`,
    };
}
