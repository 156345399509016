import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    isAdmin = new BehaviorSubject<boolean>(false);

    constructor() {}
    
    _init(status: boolean): void {
        this.isAdmin = new BehaviorSubject(status);
    }
}
