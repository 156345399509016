import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { FuseConfigService } from './../../../@fuse/services/config.service';
import { AuthService } from './../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor (
    private fuseConfig: FuseConfigService,
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.auth.isLogged()) {
        return true;
      }
  
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }})
        .then(res => {
          this.fuseConfig.setConfig({
              layout: {
                  navigation: 'none',
                  toolbar   : 'none',
                  footer    : 'none'
              }
          });
      });
      return false;
  }
}
