import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { NoAccessGuard } from './core/guards/no-access.guard';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/auth/auth.module#AuthModule',
        canActivate: [NoAuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: './main/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: './main/setting/setting.module#SettingModule',
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules } )],
    exports: [RouterModule]
})
export class AppRoutingModule {}
