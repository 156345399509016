import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from 'app/core/services/api/api.service';
import 'rxjs/add/operator/map';
import { Notifications } from './notification';

@Injectable()
export class NotificationService {
    onNotificationChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    notifications: Notifications[];
    constructor(private _http: HttpClient, private _apiService: ApiService) {}
    get(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http
                .get(this._apiService.notification.list())
                .map((res: any) => res)
                .subscribe((response: any) => {
                    this.notifications = response;
                    resolve(response);
                }, reject);
        });
    }

    update(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http
                .put(this._apiService.notification.update(), {})
                .subscribe((response: any) => {
                    resolve(response);
                });
        });
    }
}
