import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToolbarServices {
    notificationOnChanged: BehaviorSubject<any>;

    constructor() {
        this.notificationOnChanged = new BehaviorSubject(false);
    }

    _open(): void {
        this.notificationOnChanged.next(true);
    }

    _close(): void {
        this.notificationOnChanged.next(false);
    }
}
