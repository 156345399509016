import { Component, HostBinding, Input } from '@angular/core';

import { FuseNavigationItem } from '@fuse/types';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent
{
    @HostBinding('class')
    classes = 'nav-item';
    rolName: any;
    @Input()
    item: FuseNavigationItem;

    /**
     * Constructor
     */
    constructor(private _storage: LocalStorageService, private _authService: AuthService)
    {
        this.rolName = this._authService.getRol();
    }

    clearFilters(){
        localStorage.removeItem('filters');
    }
}
