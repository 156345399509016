import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnInit,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ListNotification } from '../notification';
import { Router } from '@angular/router';
import { ToolbarComponent } from '../toolbar.component';
import { ToolbarServices } from '../toolbar.services';

@Component({
    selector: 'toolbar-notification',
    templateUrl: './toolbar-notification.component.html',
    styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent implements OnInit {
    @Output() menuSelect: EventEmitter<string> = new EventEmitter<string>();
    @Input() notifications: ListNotification[];
    constructor(
        private _router: Router,
        private _toolbarServices: ToolbarServices
    ) {}

    selectMenu(value): void {
        this.menuSelect.emit(value);
    }
    ngOnInit(): void {
        console.log('yate');
    }
    go(item): void {
        const code = item.lead_code;
        let url = ``;
        if (item.type === 'pending-authorization') {
            url = `/dashboard/opportunities/${code}?auth=true`;
        } else {
            url = `/dashboard/opportunities/${code}`;
        }
        this._router.navigateByUrl(url).then(() => {
            this._toolbarServices._close();
        });
    }
}
